@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
}

.container {
    max-width: 1000px;
    margin: 30px auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 30px;
    border-radius: 5px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.btn {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
}

.btn:focus {
    outline: none;
}

.btn:active {
    transform: scale(0.98);
}

.btn-block {
    display: block;
    width: 100%;
}

.input-content {
    min-height: 100px;
}

.post {
    background: white;
    border: 2px solid green;
    margin: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.post.reminder {
    border-left: 5px solid green;
}

.post h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-form {
    margin-bottom: 40px;
}

.split-form {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.split-form-one {
    flex-basis: 60%;
}

.split-form-split {
    flex-basis: 10%;
}

.split-form-two {
    flex-basis: 30%;
}

.split-image-search {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.split-image-search-one {
    flex-basis: 55%;
}

.split-image-search-first-split {
    flex-basis: 4%;
}

.split-image-search-second-split {
    flex-basis: 11%;
}

.split-image-search-two {
    margin-top: 20px;
    flex-basis: 30%;
}

.form-control {
    margin: 20px 0;
}

.form-control label {
    display: block;
}

.form-control input {
    width: 100%;
    height: 40px;
    margin: 5px;
    padding: 3px 7px;
    font-size: 17px;
}

.form-control-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-control-check label {
    flex: 1;
}

.form-control-check input {
    flex: 2;
    height: 20px;
}

.divider {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #e3e3e3;
}

.post-header {
    display: flex;
    justify-content: space-between;
}

.post-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.post-body-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-body-item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    margin-right: 100px;
}

.align-horizontal {
    display: flex;
}

.post-footer {
    display: flex;
    justify-content: center;
}

.emoji-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.like-container {
    display: flex;
    justify-content: space-between;
}

.laugh-container {
    display: flex;
    justify-content: space-between;
}

footer {
    margin-top: 30px;
    text-align: center;
}

.card-list {
    column-count: 3;
}

.container {
    margin: 0 auto;
    max-width: 1000px;
    padding: 40px;
}

.form {
    display: grid;
}

.button:hover {
    background-color: rgba(0, 0, 0, 0.85);
}

.card {
    margin: 20px;
    border-radius: 4px;
    background: #f8f9fa;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
        0.3s box-shadow,
        0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    cursor: pointer;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card--image {
    flex: 100%;
    margin-top: 1rem;
    border-radius: 10px;
}

.card-list {
    column-count: 3;
}

.description-space {
    height: 50px;
}

@media (min-width: 768px) {
    .form {
        grid-template-columns: auto 1fr auto;
        grid-gap: 1rem;
        align-items: center;
    }
    .input {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 600px) {
    .card-list {
        column-count: 1;
    }
}
